import React from "react";
import { Container, Text, Button } from "@atoms";

const Error404 = ({ heading, descriptor, secondaryButton, button }) => {
  return (
    <Container>
      <div className="flex min-h-[50vh] flex-col gap-12 py-20 md:py-28">
        <div className="relative lg:-left-12">
          <Text variant="headingSm" className="max-w-[16ch]">
            {heading}
          </Text>
        </div>
        <Text richText>{descriptor}</Text>
        {(button?.url || secondaryButton?.url) && (
          <div className="flex flex-col gap-6">
            {button?.url && <Button to={button.url}>{button.text}</Button>}
            {secondaryButton?.url && (
              <Button to={secondaryButton.url}>{secondaryButton.text}</Button>
            )}
          </div>
        )}
      </div>
    </Container>
  );
};

export default Error404;
